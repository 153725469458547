import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../howto.css"

const HowTo = () => (
  <Layout>
    <SEO title="How to add friends " />
    <div className="grid_columns">
      <div className="grid">
        <div className="row">
          <h2 className="title">
            What is <strong>Premium Access?</strong>
          </h2>
          <div className="subtext">
            Purchase of Premium Access will give you access to one of your
            Friends personal <strong>uncensored</strong> social media accounts.
          </div>
        </div>
        <div className="row">
          <h2 className="title">
            <strong>How</strong> does is work?
          </h2>
          <ol className="list">
            <li className="item">
              <strong>Find</strong> your Username
            </li>
            <li className="item">
              <strong>Join</strong> one of your Friends
            </li>
            <li className="item">
              <strong>Retrieve</strong> her private information
              <small>from AddFriends.com/vip/</small>
            </li>
            <li className="item">
              <strong>Add</strong> her as a friend!
              <small>(requests generally accepted with in 60 minutes)</small>
            </li>
          </ol>
        </div>
      </div>
      <div className="grid second">
        <div className="phone">
          <Image alt="How Does This Work?" filename="phone01.png" />
        </div>
      </div>
    </div>
    <div className="bottom_more">
      More questions? <Link to="/contact-us">Click here to Contact Us!</Link>
    </div>
  </Layout>
)

export default HowTo
